<template>
  <div :class="avatarClass">
    <v-tooltip top :disabled="tooltip !== true">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar :size="size" :color="avatarIconColor" :class="avatarIconClass" v-bind="attrs" v-on="on">
          <img v-if="user && user.photo" :src="user.photo.file_url" @error="setDefaultImage" alt="">
          <span v-else class="white--text text-h6">{{ getInitialName(user.name) }}</span>
        </v-avatar>
      </template>
      <span class="d-block">{{ user.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'LoginAvatar',
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 32
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    avatarClass: {
      type: [Array, Object],
      default: () => ['d-inline-block']
    }
  },
  computed: {
    avatarIconColor () {
      if (this.user?.is_admin_user) {
        return 'primary'
      } else if (this.user?.is_end_user) {
        return 'secondary'
      }
      return 'grey'
    },
    avatarIconClass () {
      return {
        'v-avatar-border-thin': this.size < 32
      }
    }
  },
  methods: {
    getInitialName (name) {
      return name.charAt(0)
    },
    setDefaultImage (event) {
      // 画像が読み込めなかった場合にデフォルト画像を表示する
      event.target.src = require('@/assets/ico/ico_no-user-photo.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-avatar {
  box-sizing: content-box;
  border: solid 2px;
  &.v-avatar-border-thin {
    border-width: 1px;
  }
  img {
    object-fit: cover;
    object-position: center;
  }
}
</style>
